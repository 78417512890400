// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/Loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "name": "Login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__User__Login' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/User/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Dashboard'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/creator",
        "name": "Creator",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Creator' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Creator'), loading: LoadingComponent}),
        "icon": "user",
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/profile",
        "name": "Profile",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Profile' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Profile'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/story",
        "name": "Story",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Story' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Story'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/secretgallery",
        "name": "Secret Gallery",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__SecretGallery' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/SecretGallery'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/banner",
        "name": "Banner",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Banner' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Banner'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/status-messages",
        "name": "Status Messages",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatusMessages' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/StatusMessages'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/report",
        "name": "Report",
        "icon": "picLeft",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Report' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Report'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/billing",
        "name": "Billing",
        "icon": "fileDone",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Billing' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/Billing'), loading: LoadingComponent}),
        "access": "isManagement",
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/vhosts/dev.escort.ch/cp.dev.escort.ch/application/cp/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
