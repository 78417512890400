import { useModel, useIntl } from 'umi';
import Logo from './assets/images/logo.svg';
import './assets/css/index.css';


export default () => {

  const intl = useIntl();
  const currentLang = localStorage.getItem('umi_locale');

  return (
    <>
      <div className="ant-pro-global-header-logo">
        <a>
          <img src={Logo} alt="logo" />
          <h1>{intl.formatMessage({id: 'app.settings.title', defaultMessage: 'ControlPanel'})}</h1>
        </a>
      </div>
    </>
  );
};
