// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import PicLeftOutlined from '@ant-design/icons/PicLeftOutlined';
import FileDoneOutlined from '@ant-design/icons/FileDoneOutlined'

export default {
  HomeOutlined,
UserOutlined,
PicLeftOutlined,
FileDoneOutlined
}
    