// @ts-ignore

/* eslint-disable */
import { request, useModel, useIntl } from 'umi';
import { Alert, message, Tabs } from 'antd';
import Helper from '@/helper/Helper';
import Logger from '@/helper/Logger';


/** GET /api/checkMaintance */
export async function checkMaintance(options) {
  return fetch('/api/checkMaintance?_format=json');
}

/** GET /api/currentUser */
export async function currentUser(options) {

  const currentUser = Helper.getCurrentUser();
  //console.log(currentUser);

  const response = request('/api/rest/webuser?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });

  const tmpResponse = await response;

  Helper.setItem('userDetail', JSON.stringify(tmpResponse.user));
  Helper.setItem('roles', JSON.stringify(tmpResponse.roles));
  Helper.setItem('permissions', JSON.stringify(tmpResponse.permissions));


  return response;
}


/** GET /api/jwt/token */
export async function refreshToken(options) {

  return request('/api/jwt/token?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}


/** POST /api/logout */
export async function logout(body, options) {

  Helper.clearLocalStorage();

  return true;
}


/** POST /api/login */
export async function login(body, options) {
  const returnResponse = request('/api/rest/webuser/login/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'login', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'login', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/lostPassword */
export async function lostPassword(body, options) {
  const returnResponse = request('/api/user/lost-password?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'lostPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'lostPassword', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/lostPassword */
export async function resetPassword(body, options) {
  const returnResponse = request('/api/user/lost-password-reset?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'resetPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'resetPassword', 'error', '');
    return error;
  });
  return returnResponse;
}


/** POST /api/rest/notices */
export async function getNotices(body, options) {
  return null;
}




/**
 * profile_management api
 */

 /** GET /api/rest/profile_management/get */
 export async function getProfile(params, options) {

  const response = request('/api/rest/profile_management/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}


 /** GET /api/rest/profile/get */
 export async function getGuestProfile(params, options) {

  const response = request('/api/rest/profile/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}







/**
 * creator api
 */

 /** GET /api/rest/creator/get */
 export async function getCreator(params, options) {
   const response = request('/api/rest/creator_management/get?_format=json', {
     skipErrorHandler: true,
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
     },
     params: { ...params },
     ...(options || {}),
   });

   return response;
 }

 /** POST /api/rest/creator/get */
 export async function postCreator(params, options) {

   const response = request('/api/rest/creator_management/create?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    })

    return response;
 }




/**
 * story_management api
 */

 /** GET /api/rest/story_management/get */
 export async function getStory(params, options) {

   const response = request('/api/rest/story_management/get?_format=json', {
     skipErrorHandler: true,
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
     },
     params: { ...params },
     ...(options || {}),
   });

   return response;
 }





 /**
  * banner_management api
  */

  /** GET /api/rest/banner_management/get */
  export async function getBanner(params, options) {

    const response = request('/api/rest/banner_management/get?_format=json', {
      skipErrorHandler: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...params },
      ...(options || {}),
    });

    return response;
  }





/**
 * support api
 */
/** GET /api/rest/support_ticket/get */
export async function getSupportTicket(params, options) {

  const response = request('/api/rest/support_management/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}



export async function postSupportTicket(body, options) {
  const returnResponse = request('/api/rest/support_management/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'createStory', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'createStory', 'error', '');
    return error;
  });
  return returnResponse;
}



/**
 * Transaction api
 */
/** GET /api/rest/transactions_management/get */
export async function getTransaction(params, options) {

  const response = request('/api/rest/transactions_management/get?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  });

  return response;
}

export async function postTransaction(params, options) {

  const response = request('/api/rest/transactions_management/create?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: params,
    ...(options || {}),
  });

  return response;
}




/**
 * id_verification_management api
 */

 /** GET /api/rest/id_verification_management/get */
 export async function getVerification(params, options) {

   const response = request('/api/rest/verification_management/get?_format=json', {
     skipErrorHandler: true,
     method: 'GET',
     headers: {
       'Content-Type': 'application/json',
     },
     params: { ...params },
     ...(options || {}),
   });

   return response;
 }



 /**
  * report api
  */

  /** GET /api/rest/report_management/get */
  export async function getReport(params, options) {

    const response = request('/api/rest/report_management/get?_format=json', {
      skipErrorHandler: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...params },
      ...(options || {}),
    });

    return response;
  }



  /** GET /api/rest/report_management/get */
  export async function getDashboardStatistic(params, options) {

    const response = request('/api/rest/dashboard/statistic?_format=json', {
      skipErrorHandler: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...params },
      ...(options || {}),
    });

    return response;
  }




  /**
  * GET secret galery
  */

  /** GET /api/rest/secret_gallery_management/get */
  export async function getSecretGallery(params, options) {

    const response = request('/api/rest/secret_gallery_management/get?_format=json', {
      skipErrorHandler: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...params },
      ...(options || {}),
    });

    return response;
  }


  /**
  * GET Status Messages
  */

  /** GET /api/rest/status_messages_management/get */
  export async function getStatusMessages(params, options) {

    const response = request('/api/rest/status_messages_management/get?_format=json', {
      skipErrorHandler: true,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: { ...params },
      ...(options || {}),
    });

    return response;
  }

