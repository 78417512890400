import React, { useState, useEffect } from 'react';

import { PageLoading } from '@ant-design/pro-layout';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import { useModel} from 'umi';
import moment from 'moment-timezone';
import { ConfigProvider } from 'antd';
import Helper from '@/helper/Helper';

import '../config/interceptors.js';

import './assets/css/app.css';

const loginPath = '/user/login';



// Set CI style settings
ConfigProvider.config({
  theme: {
    primaryColor: '#34302e',
    linkColor: '#34302e',
  }
});




localStorage.setItem('umi_locale', 'en-GB');

moment.tz.setDefault('Europe/Zurich');

export const initialStateConfig = {
  loading: <PageLoading />,
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */

export async function getInitialState() {

  const fetchUser = async () => {
    //console.log('fetchUser');
    try {
      const msg = await queryCurrentUser();
      //console.log(msg);
      return msg.user;
    } catch (error) {
      //console.log(error);
      //history.push(loginPath);
    }
    return undefined;
  };

  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUser();
    return {
      fetchUser,
      currentUser,
      settings: {},
    };
  }

  return {
    fetchUser,
    settings: {},
  };
}

export const layout = ({ initialState }) => {

  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    headerTitleRender: () => <Header />,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      if ((!initialState?.currentUser) && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    menuHeaderRender: undefined,
    ...initialState?.settings,
  };
};



window.connectModel = (key, name) => {
  return (WrappedComponent) => {
    return (props) => {
      const model = useModel(name);
      const data = { [key]: model };
      return <WrappedComponent {...props} {...data} />;
    };
  };
};
