import { Component } from 'react';
import countriesJson from 'country-region-data/data.json';
import Logger from '@/helper/Logger';
import Storage from '@/helper/Storage';
import { injectIntl, history } from 'umi';
import { stringify } from 'querystring';
import moment from 'moment';


class Helper extends Component {
  constructor(props) {
    super(props);
  }

  static setItem(key, value) {
    //console.log(value);
    Storage.setItem(key, value);
  }

  static getItem(key) {
    return Storage.getItem(key);
  }


  static clearLocalStorage() {
    let currentLang = localStorage.getItem('umi_locale');
    localStorage.clear();
    localStorage.setItem('umi_locale', currentLang);
  }

  static getCountries() {

    let countriesJsonArray;

    const regionNames = new Intl.DisplayNames(
      [Helper.getCurrentLang()], {type: 'region'}
    );

    countriesJsonArray = countriesJson.map(item => {
      const obj = Object.assign({}, item);
      obj['countryName'] =regionNames.of(item.countryShortCode);
      return obj;
    });


    const countriesJsonNew = countriesJsonArray.map(({
      countryName: label,
      countryShortCode: value,
      ...rest
    }) => ({
      label,
      value,
      ...rest
    }));

    Logger.logAction(countriesJsonNew, 'getCountries', 'info', '');

    return countriesJsonNew;

  }

  static getCountryByValue(value) {
    const country = Helper.getCountries().find((obj) => {
      return obj.value === value;
    });
    return (country) ? country['label'] : '';
  }

  static getCurrentLang() {
    let currentLang = localStorage.getItem('umi_locale');
    currentLang = currentLang.split('-');
    return currentLang[0];
  }

  static translateString(str) {
    return str;
  }




  static getCurrentUser() {
    const user = Storage.getItem('user');
    if(user) {
      return user.current_user;
    }
  }

  static getUser() {
    const user = Storage.getItem('user');
    if(user) {
      return user;
    }
  }

  static getUserDetail() {
    const userDetail = Storage.getItem('userDetail');
    if(userDetail) {
      return userDetail;
    }
  }


  static getAccessToken() {
    const user = Storage.getItem('user');
    if(user) {
      return user.access_token;
    }
  }

  static waitTime = (time = 100) => {
      return new Promise((resolve) => {
          setTimeout(() => {
              resolve(true);
          }, time);
      });
  };

}

export default injectIntl(Helper);
