import request, { extend } from 'umi-request';
import { useIntl, history, IntlProvider } from 'umi';
import { Alert, message, Tabs } from 'antd';
import { checkMaintance } from '@/services/ant-design-pro/api';
import Helper from '@/helper/Helper'
import moment from 'moment-timezone';



moment.tz.setDefault('Europe/Zurich');




// Same as the last one
request.interceptors.request.use(
  (url, options) => {


    const accessToken = Helper.getAccessToken();

    const isUserLogin = url.includes("/user/login");
    const isPasswordLost = url.includes("/user/lost-password");
    const isRegister = url.includes("/api/rest/esc/register/create");

    let authHeader = {};
    if(!isUserLogin && !isPasswordLost && !isRegister) {
      authHeader = { Authorization: 'Bearer ' + accessToken };
    }

    return {
      url: url,
      options: { ...options, interceptors: true, headers: authHeader }
    };
  },
  { global: true }
);





request.interceptors.response.use(async (response) => {

  // checkMaintance

  if(response.status === 503 || response.status === 403) {
    /* Logout User */
    Helper.clearLocalStorage();
    history.push('/user/login');
    return null;
  }

  const data = await response.clone().json();


  if(!data.success && data.message) {
    message.error(Helper.translateString(data.message));
  }

  if(response.status === 302) {
    return null;
  } else if(response.status === 400) {
    return data;
  }
  return response;
})
