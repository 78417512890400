//console.log('defaultSettings');

const Settings = {
  navTheme: 'light',
  primaryColor: '#d8232a',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'CP escort.ch ',
  pwa: true,
  logo: '/logo.png',
  iconfontUrl: '',
};
export default Settings;
