import { useIntl } from 'umi';
import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import { useModel } from 'umi';
import SessionCountdown from '@/components/SessionCountdown';

import './assets/css/index.css';



export default () => {
  return (
    <>
    <SessionCountdown />
    </>
  );
};
