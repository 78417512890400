// @ts-nocheck

  import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import PicLeftOutlined from '@ant-design/icons/es/icons/PicLeftOutlined';
import FileDoneOutlined from '@ant-design/icons/es/icons/FileDoneOutlined'
  export default {
    HomeOutlined,
UserOutlined,
PicLeftOutlined,
FileDoneOutlined
  }