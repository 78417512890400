import React, { Component } from 'react';
import Countdown from 'react-countdown';
import jwt_decode from "jwt-decode";
import { Modal, Button, Row, Col, Alert, Divider, Space } from 'antd';
import { history } from 'umi';
import { refreshToken } from '@/services/ant-design-pro/api';
import IdleTimer from 'react-idle-timer';
import Helper from '@/helper/Helper';



class SessionCountdown extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      jwtToken: null,
      decodedToken: null,
      logoutTimer: Date.now() + 30000
    }


    this.countdownRef = React.createRef();
    this.logoutCountdownRef = React.createRef();

    this.onRefreshToken = this.onRefreshToken.bind(this);
    this.initTimer = this.initTimer.bind(this);
  }


  componentDidUpdate(previousProps, previousState) {
    if (previousProps.initialState !== this.props.initialState) {
      this.initTimer();
    }
  }

  componentDidMount() {
    this.initTimer();
  }

  initTimer() {
    let jwtToken = Helper.getAccessToken();

    if(jwtToken) {
      let decodedToken = jwt_decode(jwtToken);


      if((decodedToken.exp * 1000) - 250000 <= 0) {

        this.setState({
          isModalVisible: true,
        })

      }

      this.setState({
        jwtToken: jwtToken,
        decodedToken: decodedToken
      })
    }
  }

  onRefreshToken(e) {
    const component = this;
    let result = refreshToken({}).then(function(response) {
      let user = Helper.getUser();
      let accessToken = Helper.getAccessToken();
      if(user && accessToken && response) {
        user.access_token = response.token;
        Helper.setItem('user', JSON.stringify(user));

        let jwtToken = accessToken;
        let decodedToken = jwt_decode(jwtToken);
        component.setState({
          jwtToken: jwtToken,
          decodedToken: decodedToken,
          isModalVisible: false,
        }, function(e) {
          component.countdownRef.current.start();
          //component.logoutCountdownRef.current.stop();
        })
      } else {
        Helper.clearLocalStorage();
        window.location = '/user/login';
      }
    })
  }






  render() {
    let component = this;
    return (
      <>
      {this.state.decodedToken &&
        <>
        <div style={{color: '#000', display: 'none'}}>
          SessionCounter:
          <Countdown
            ref={this.countdownRef}
            onComplete={() => {
              component.onRefreshToken();
              /*
              component.setState({
                isModalVisible: true,
                logoutTimer: Date.now() + 30000
              })*/
            }}
            date={(this.state.decodedToken.exp * 1000) - 250000}
          />
          </div>

        <Modal zIndex={10001} title={false} closable={false} footer={false} visible={this.state.isModalVisible}>
          <Alert
              message="Session Expires"
              description="Ihre Sitzung läuft bald ab. Möchten Sie diese erneuern?"
              type="warning"
              showIcon
            />

            <Divider />


            {this.state.isModalVisible &&
              <Countdown
                ref={this.logoutCountdownRef}
                onComplete={() => {
                  window.location = '/user/login';
                  //history.push('/user/login')
                }}
                date={this.state.logoutTimer}
              />
            }



            <Divider />

            <Space>
              <Button type="success" onClick={(e) => this.onRefreshToken(e)}>{'Ja'}</Button>
              <Button type="danger" onClick={() => history.push('/user/login')}>{'Logout'}</Button>
            </Space>
        </Modal>
        </>
      }


      </>
    );
  }
}

export default SessionCountdown;
