/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */


 import Helper from '@/helper/Helper';



export default function access(initialState) {
  const { currentUser } = initialState || {};
  //console.log(initialState);

  const permissions = Helper.getItem('permissions');
  //console.log(permissions);
  const roles = Helper.getItem('roles');
  //console.log(roles);

  return {
    isManagement: () => (roles && roles.includes("management")),
  };
}
