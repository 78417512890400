import { Component } from 'react';

import { EncryptStorage } from 'encrypt-storage';


const secretKey = '1234567890';
const encryptStorage = new EncryptStorage(secretKey, {
  prefix: '@cp',
});


class Storage extends Component {
  constructor(props) {
    super(props);
  }

  static setItem(key, value) {
    encryptStorage.setItem(key, value);
    localStorage.setItem(key, value);
  }


  
  static getItem(key) {
    const itemEncrypt = encryptStorage.getItem(key);
    const item = localStorage.getItem(key);
    //console.log(item);
    //console.log(itemEncrypt);

    return itemEncrypt;
  }
}

export default Storage;
